/* You can add global styles to this file, and also import other style files */

// this is our just created themes.scss file, make sure the path to the file is correct
@use "themes" as *;
// framework component styles
@use "@nebular/theme/styles/globals" as *;
// install the framework styles
@include nb-install() {
  @include nb-theme-global();
}

.background-red {
  background: nb-theme(color-primary-500);
}

.background-white {
  background: nb-theme(color-basic-100);
}

.cursor-pointer {
  cursor: pointer;
}

.text-danger {
  color: nb-theme(color-danger-500);
}

.text-italic {
  font-style: italic;
}

.text-small {
  font-size: 0.75rem;
}

.text-large {
  font-size: 1.5rem;
}

.text-center {
  text-align: center;
}

.subtitle-text {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #cdc8c8;
}

a {
  font-family: Roboto;
  font-weight: 500;
}

.flex-x {
  display: flex;
}

.flex-x-between {
  display: flex;
  justify-content: space-between;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.column-gap-1 {
  column-gap: 1rem;
}

.column-gap-2 {
  column-gap: 3rem;
}

.width-full {
  width: 100%;
}

.width-fit {
  width: fit-content;
}

.width-3 {
  width: 8rem !important;
}

.width-4 {
  width: 20rem !important;
}

.max-width-6 {
  max-width: 100rem !important;
}

.height-3 {
  height: 8rem !important;
}

.height-full {
  height: 100%;
}

.full-width-height {
  margin: -36px 0px 0px -36px;
  width: calc(100% + 72px);
  height: calc(100% + 48px);
  overflow: scroll;
}

.border-none {
  border: none;
}

.border-bottom-white {
  border: 2px solid white !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.card-icon {
  max-width: 12rem;
  max-height: 8rem;
  vertical-align: middle;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-2 {
  margin-top: 3rem;
}

.margin-top-3 {
  margin-top: 8rem;
}

.margin-left-2 {
  margin-left: 3rem;
}

.margin-right-1 {
  margin-right: 1rem;
}

.margin-block-auto {
  margin-block: auto;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-inline-auto {
  margin-inline: auto;
}
